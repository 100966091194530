export const CANVAS_STROKE_COLOR = '#000'
export const DEFAULT_LINE_WEIGHT = 15;

export const STATUS = {
  WRITING: 1,
  SELECTING: 2,
  SHOWING_SCORE: 3,
};
export const NUM_DISPLAY = 10;
export const NUM_DISPLAY_ROW = 4;
export const CHAR_TYPES = ['hira', 'kata', 'kanji', 'latin', 'num'];
export const CHAR_DISPLAYS = {
  'hira': 'ひらがな',
  'kata': 'カタカナ',
  'kanji': '漢字',
  'latin': 'ローマ字',
  'num': '数字',
};
export const CHAR_COUNTS = {
  'num': 10,
  'latin': 52,
  'hira': 75,
  'kata': 71,
  'kanji': 2967,
  'total': 3175,
}
export const SAMPLE_IMAGE_DIR = '20200314';

const hiragana = [
  ['あ', 'い', 'う', 'え', 'お'],
  ['か', 'き', 'く', 'け', 'こ'],
  ['さ', 'し', 'す', 'せ', 'そ'],
  ['た', 'ち', 'つ', 'て', 'と'],
  ['な', 'に', 'ぬ', 'ね', 'の'],
  ['は', 'ひ', 'ふ', 'へ', 'ほ'],
  ['ま', 'み', 'む', 'め', 'も'],
  ['や',   '', 'ゆ',   '', 'よ'],
  ['ら', 'り', 'る', 'れ', 'ろ'],
  ['わ',   '',   '',   '', 'を'],
  ['ん',   '',   '',   '',   ''],
  ['が', 'ぎ', 'ぐ', 'げ', 'ご'],
  ['ざ', 'じ', 'ず', 'ぜ', 'ぞ'],
  ['だ', 'ぢ', 'づ', 'で', 'ど'],
  ['ば', 'び', 'ぶ', 'べ', 'ぼ'],
  ['ぱ', 'ぴ', 'ぷ', 'ぺ', 'ぽ'],
  //['ぁ', 'ぃ', 'ぅ', 'ぇ', 'ぉ'],
  [  '',   '', 'っ',   '',   ''],
  ['ゃ',   '', 'ゅ',   '', 'ょ'],
];

const katakana = [
  ['ア', 'イ', 'ウ', 'エ', 'オ'],
  ['カ', 'キ', 'ク', 'ケ', 'コ'],
  ['サ', 'シ', 'ス', 'セ', 'ソ'],
  ['タ', 'チ', 'ツ', 'テ', 'ト'],
  ['ナ', 'ニ', 'ヌ', 'ネ', 'ノ'],
  ['ハ', 'ヒ', 'フ', 'ヘ', 'ホ'],
  ['マ', 'ミ', 'ム', 'メ', 'モ'],
  ['ヤ',   '', 'ユ',   '', 'ヨ'],
  ['ラ', 'リ', 'ル', 'レ', 'ロ'],
  ['ワ',   '',   '',   '', 'ヲ'],
  ['ン',   '',   '',   '',   ''],
  ['ガ', 'ギ', 'グ', 'ゲ', 'ゴ'],
  ['ザ', 'ジ', 'ズ', 'ゼ', 'ゾ'],
  ['ダ', 'ヂ', 'ヅ', 'デ', 'ド'],
  ['バ', 'ビ', 'ブ', 'ベ', 'ボ'],
  ['パ', 'ピ', 'プ', 'ペ', 'ポ'],
  //['ァ', 'ィ', 'ゥ', 'ェ', 'ォ'],
  //['ッ',   '',   '',   '',   ''],
  //['ャ',   '', 'ュ',   '', 'ョ'],
];

const latins = [
  //['',   '',   '',   '',   ''],
  ['A',   'B',   'C',   'D',   'E'],
  ['F',   'G',   'H',   'I',   'J'],
  ['K',   'L',   'M',   'N',   'O'],
  ['P',   'Q',   'R',   'S',   'T'],
  ['U',   'V',   'W',   'X',   'Y'],
  ['Z',   '',   '',   '',   ''],
  ['a',   'b',   'c',   'd',   'e'],
  ['f',   'g',   'h',   'i',   'j'],
  ['k',   'l',   'm',   'n',   'o'],
  ['p',   'q',   'r',   's',   't'],
  ['u',   'v',   'w',   'x',   'y'],
  ['z',   '',   '',   '',   ''],
];

const nums = [
  ['1',   '2',   '3',   '4',   '5'],
  ['6',   '7',   '8',   '9',   '0'],
];
export const CHARS = {
  'hira': hiragana,
  'kata': katakana,
  'kanji': [],
  'latin': latins,
  'num': nums,
};

